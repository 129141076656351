import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { trackEvent } from 'src/common/tracking';
import { setInquiryId } from 'src/booking-inquiry/ducks/inquiry';
import type { ReduxState } from '../../../../types/reduxState';
import { streamlineSubmit, streamlineNextPage, commonSubmit } from '../../../submit';
import { PAGES } from '../../../constants';
import { rffSubmitResponse } from '../../../../common/helpers';
import { getBoat, getInquiry } from '../../../../common/utils/reduxStoreSelectors';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { userPatchCall, updateUser, getIsLoggedIn } from '../../../../common/ducks/user';
import { BackButton } from '../../../../common/components/BackButton';
import ContactDetails from '../../../../common/components/fieldsets/ContactDetails';
import MarketingConsent from '../../../../common/components/fieldsets/MarketingConsent';
import FormError from '../../../../common/components/FormError';
import Button from '../../../../common/components/Button';
import CloseButton from '../../../../common/components/CloseButton';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import SignInOrSwitchAccounts from '../../../../common/components/SignInOrSwitchAccounts';
import useSavedInquiry from '../../hooks/useSavedInquiry';
import s from './Contact.module.scss';

// We want initialValues to be undefined
// if there's no data to be mapped into it,
// rather than initializing individual values
// to undefined, which will clear data.
const getContactInitialValues = ({ user }: ReduxState) => (user.get('email')
  ? {
    first_name: user.get('first_name'),
    last_name: user.get('last_name'),
    email: user.get('email'),
    phone: user.get('phone'),
    marketing_consent: user.get('marketing_consent'),
  }
  : undefined);

const nextPage = (inquiryHasMismatches?: boolean) => {
  if (inquiryHasMismatches) {
    return PAGES.MISMATCHES;
  }
  return PAGES.MATCH;
};

// TODO: figure out how to test the end behavior. We broke this when changing
// contactEmail -> email and it wasn't picked up by any of our tests.

type ContactProps = {
  streamlineInquiry?: boolean;
  inquiryHasMismatches?: boolean;
};

const Contact: FC<ContactProps> = ({
  streamlineInquiry,
  inquiryHasMismatches,
}) => {
  const location = useLocation();
  const { goBack } = useHistory();
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, AnyAction>>();
  const returnUrl = getReturnUrl(location);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const initialValues = useSelector(getContactInitialValues);
  const inquiry = useSelector(getInquiry);
  const boat = useSelector(getBoat);
  const {
    storeInquiryInLocalStorage,
    storeInquiryInCookies,
  } = useSavedInquiry(boat);

  const onSubmitSuccess = useOnSubmitLocationChange(
    nextPage(
      inquiryHasMismatches,
    ),
  );
  const onStreamlineSubmitSuccess = useOnSubmitLocationChange(streamlineNextPage(inquiry));
  const loggedInTransition = useOnSubmitLocationChange(PAGES.PASSWORD);

  // Save the current inquiry when the user logs in or switches accounts
  // to prevent losing their progress during authentication.
  const onLoginClick = useCallback(() => {
    storeInquiryInLocalStorage(inquiry.toJS());
    storeInquiryInCookies(inquiry.toJS());
  }, [inquiry, storeInquiryInCookies, storeInquiryInLocalStorage]);

  // TODO: the logic in here can probably be simplified a bit more. I'm not
  // sure how right now though.
  const onSubmit = useCallback(
    (values, { getState }) => {
      trackEvent('send_inquiry_s8_user', { p1: 'Next' });
      const { dirty } = getState();
      // If not logged in, we'll stick their details into the
      // user reducer so that the submit function can
      // send them along with the booking inquiry.
      if (!isLoggedIn) {
        dispatch(updateUser(values));
        return loggedInTransition();
      }

      // We can make these independent calls because if the user is logged in,
      // we don't actually care what order they happen in.
      return Promise.all([
        // If they're logged in and they've changed things,
        // we want to update their contact info on the backend.
        dirty && dispatch(userPatchCall(values)),
        // If the inquiry is streamlined, go ahead and submit.
        streamlineInquiry && streamlineSubmit({ inquiry: inquiry.get('details'), boat })(values),
        !inquiryHasMismatches && !streamlineInquiry && commonSubmit({ boat, inquiry: inquiry.get('details') }),
      ])
        .then((resolutions) => {
          // resolutions is the array returned by all promises.
          if (resolutions[2]) {
            const response = resolutions[2];
            if (response.ok) {
              response.json().then(({ id }) => {
                dispatch(setInquiryId(id));
              });
            }
          }
          storeInquiryInLocalStorage(inquiry.toJS());
          storeInquiryInCookies(inquiry.toJS());
          if (streamlineInquiry) {
            return onStreamlineSubmitSuccess();
          }
          return onSubmitSuccess();
        })
        .catch(rffSubmitResponse());
    },
    [
      dispatch,
      isLoggedIn,
      streamlineInquiry,
      boat,
      inquiry,
      storeInquiryInLocalStorage,
      storeInquiryInCookies,
      loggedInTransition,
      onSubmitSuccess,
      onStreamlineSubmitSuccess,
      inquiryHasMismatches,
    ],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <h1 className={s.title}>Your Contact Details</h1>
      <div className={s.message}>
        <SignInOrSwitchAccounts onLoginClick={onLoginClick} />
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitError }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className={s.form}
          >
            <div>
              {submitError && (
                <FormError
                  error={submitError}
                  className={s.error}
                />
              )}
              <ContactDetails isLoggedIn={isLoggedIn} />
              <MarketingConsent />
            </div>

            <Button
              type="submit"
              data-test="next"
              fullWidth
            >
              Next
            </Button>
          </form>
        )}
      </Form>
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default Contact;
