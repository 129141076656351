import captureException from 'src/common/utils/captureException';

export { configureScope } from '@sentry/browser';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchCatch = (response: Response | unknown) => {
  if (response instanceof Response) {
    captureException(new Error('Response Thrown'), {
      extra: {
        url: response.url,
        status: response.status,
        type: response.type,
      },
    });
  }
  // TODO: make this return useful information.
};

/**
 * Ignore various network related errors.
 */
export const ignoreSpecificNetworkErrors = (error: unknown): void => {
  // Safari throws this error when there is an issue connecting to the server.
  if (error instanceof TypeError && error.message === 'Load failed') {
    return;
  }

  // Chrome throws this error when there is an issue connecting to the server.
  if (error instanceof TypeError && error.message === 'Failed to fetch') {
    return;
  }

  // Firefox throws this error when there is an issue connecting to the server.
  if (error instanceof TypeError && error.message === 'NetworkError when attempting to fetch resource.') {
    return;
  }

  // Rethrow the error as we're not specifically handling it here.
  throw error;
};
