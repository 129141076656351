import type { Currencies } from 'src/types/common/Currencies';
import type { PaymentAddon } from 'src/types/trips/PaymentAddon';
import { BROADCAST_BOATS_FIELD, INQUIRY_ID_FIELD, INSTABOOK_LISTINGS_FIELD, PAGES } from '../../booking-inquiry/constants';
import { hasMismatches } from '../../booking-inquiry/helpers';
import {
  BoatDetail,
  ImmutableBoats,
  ImmutableSearchBoatDetails,
} from '../../types/boat/BoatDetail';
import { RequestCurrency } from '../../types/boat/RequestCurrency';
import {
  ImmutableBroadcastBoat,
  ImmutableBroadcastBoats,
  ImmutableInquiry,
  ImmutableInquiryDetails,
  RelatedInstabookListings,
} from '../../types/inquiry/Inquiry';
import { ImmutableOffer } from '../../types/offer/Offer';
import { ReduxState } from '../../types/reduxState';
import type { ImmutableTrip } from '../../types/trips/Trips';
import { ImmutableUser } from '../../types/user/User';

const boatsToFormInitialization = (broadcastBoats: ImmutableBroadcastBoats): string[] => (
  broadcastBoats.map((boat: ImmutableBroadcastBoat | undefined) => boat!.get('id')).toJS()
);

export const getCurrencies = ({ currencies }: ReduxState): Currencies => currencies;

export const getBoat = ({ boat }: ReduxState): ImmutableSearchBoatDetails => boat;

export const getPaymentAddons = ({ paymentAddons }: ReduxState): PaymentAddon[] => paymentAddons;

export const getUserBoats = ({ user }: ReduxState): ImmutableBoats => user.get('boats');

export const getInquiry = ({ inquiry }: ReduxState): ImmutableInquiry => inquiry;

export const getInquiryHasMismatches = ({ inquiry }: ReduxState): boolean => (
  hasMismatches(inquiry)
);

export const getOffer = ({ offer }: ReduxState): ImmutableOffer => offer;

export const getUser = ({ user }: ReduxState): ImmutableUser => user;

export const getFeatureToggles = (
  { user }: ReduxState,
): string[] => user.get('feature_toggles')?.toJS() ?? [];

export const getCountryCode = ({ countryCode }: ReduxState): string => countryCode;

export const getLoading = ({ loading }: ReduxState) => loading;

export const getTransaction = ({ transaction }: ReduxState) => transaction;

export const getTrip = (tripId: string) => ({ trips }: ReduxState): ImmutableTrip => (
  trips.get('results').find((t) => t?.get('pk') === Number(tripId))
);

export const getTrips = ({ trips }: ReduxState) => trips;

export const getRequestCurrency = ({ requestCurrency }: ReduxState): RequestCurrency => (
  requestCurrency
);

export const getInquiryDetails = ({ inquiry }: ReduxState): ImmutableInquiryDetails => (
  inquiry.get('details')
);

export const getSimilarListingsInitialValues = ({ inquiry }: ReduxState) => ({
  [BROADCAST_BOATS_FIELD]: boatsToFormInitialization(inquiry.get(BROADCAST_BOATS_FIELD)),
});

export const getBroadcastBoats = ({ inquiry }: ReduxState): BoatDetail[] => (
  inquiry.get(BROADCAST_BOATS_FIELD).toJS()
);

export const getInstabookListings = ({ inquiry }: ReduxState): RelatedInstabookListings[] => (
  inquiry.get(INSTABOOK_LISTINGS_FIELD).toJS()
);

export const getNextInquiryPage = ({ inquiry }: ReduxState) => (
  inquiry.get('communications_warning') ? PAGES.WARNING : PAGES.DONE
);

export const isPaymentMethodSet = ({ user }: ReduxState) => (
  !!(user.get('is_owner') && user.get('has_payout_method'))
);

export const getLinkedCaptains = ({ linkedCaptains }: ReduxState) => linkedCaptains;

export const getInquiryId = ({ inquiry }: ReduxState) => inquiry.get(INQUIRY_ID_FIELD);

export const getMobileNativeWebView = ({ mobileNativeWebView }: ReduxState) => mobileNativeWebView;
