import Cookies from 'js-cookie';
import 'isomorphic-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { pickBy } from 'lodash';

import gitShaVersion from 'src/pubsub/gitShaVersion';
import { PATHS, LOCKDOWN_PAGES } from '../common/constants';
import captureException from '../common/utils/captureException';

// We want to use URIs of the form /path/to/uri,
// rather than path/to/uri. This strips the leading
// slash so that full URI is constructed correctly.
export const stripLeadingSlash = (uri: string) => (
  uri.slice(0, 1) === '/' ? uri.slice(1) : uri
);

const updateHeaders = (headers: Record<string, string> = {}): Record<string, string> => (
  // We want to strip falsey values from the header object so that we can
  // unset the content-type header when we need to
  // "pickBy" should really be called "strip falsey values" or something.
  pickBy({
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrftoken'),
    ...gitShaVersion,
    ...headers,
  }) as Record<string, string>
);

type FetchOptions = {
  method?: 'PATCH' | 'POST' | 'DELETE' | 'GET';
  body?: string | FormData;
  signal?: AbortSignal;
  headers?: Record<string, string>;
};

export type Fetcher = (url: string, option?: FetchOptions) => Promise<Response>;

/**
 * Low level wrapper around `fetch`.
 * Expects a fully qualified url. e.g. `https://www.getmyboat.com/api/v4/auth/user/`.
 */
export const backendFetch: Fetcher = (url, options = {}) => (
  fetch(
    url,
    {
      // This is typically the default, but it's worth setting explicitly:
      // https://github.com/github/fetch#sending-cookies
      credentials: 'same-origin',
      ...options,
      headers: updateHeaders(options.headers),
    },
  )
);

const BASE_API_URI = '/api/v4/';
const FALLBACK_API_HOSTNAME_PROTOCOL = 'https://www.getmyboat.com';
export const TEST_API_URL = FALLBACK_API_HOSTNAME_PROTOCOL + BASE_API_URI;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const determineBackend = (uri: string): string => {
  if (process.env.NODE_ENV === 'test') {
    return FALLBACK_API_HOSTNAME_PROTOCOL;
  }
  return '';
};

/**
 * Fetch for calling gmb api endpoints.
 * - Expects the endpoint uri without `/api/v4/` prefix. e.g. `/auth/user/`
 * - Adds required headers
 */
export const apiFetchBase: Fetcher = (uri, options = {}) => {
  const domain = determineBackend(uri);
  const url = `${domain}${BASE_API_URI}${stripLeadingSlash(uri)}`;
  return backendFetch(
    url,
    options,
  );
};

// TODO: This is only going to work on the frontend.
// If we get user authentication working on the backend,
// it will require another mechanism.

// This should probably go directly into the auth thunk
// rather than being on every request.
export const badUserWrapper = (fetcher: Fetcher): Fetcher => async (...args) => {
  const response = await fetcher(...args);
  if (typeof window !== 'undefined' && response.status === 401
    && !LOCKDOWN_PAGES.includes(window.location.pathname)
  ) {
    // The clone() call means that we can unpack the
    // body more than once - the clone's json()
    // is separate from the response's, so we don't
    // run into the only-unpack-once problem.
    try {
      const clone = response.clone();
      const body = await clone.json();
      if (body.banned) {
        window.location.replace(PATHS.BANNED);
      } else if (body.suspended) {
        window.location.replace(PATHS.SUSPENDED);
      }
    } catch (err) {
      captureException(err, {
        extra: {
          fn: 'badUserWrapper',
          url: response.url,
        },
      });
    }
  }
  return response;
};

export default badUserWrapper(apiFetchBase);
