import { fromJS, Map } from 'immutable';
import { stringify } from 'query-string';
import Cookies from 'js-cookie';

import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { fetchCatch, configureScope, ignoreSpecificNetworkErrors } from 'src/core/sentry';
import { apiFetchThunk } from './fetch';
import { setSiftUser, clearSiftUser } from '../siftscience';
import { setRequestCurrency } from './requestCurrency';
import { clearTrips } from '../../inbox/ducks/trips';
import { clearTransaction } from '../../inbox/ducks/transaction';
import { clearMessages } from '../../inbox/ducks/messages';
import { RECAPTCHA_COOKIE_NAME } from '../utils/loadRecaptchaSdk';
import captureException from '../utils/captureException';
import dynamicImport from '../utils/dynamicImport';

import { trackRegistration } from '../tracking';
import { ReduxState } from '../../types/reduxState';
import { GuestUser, ImmutableUser, User } from '../../types/user/User';
import { BoatDetail, MinimalBoatDetail } from '../../types/boat/BoatDetail';
import { MFAMethod } from '../../types/common/MFAMethods';
import { IMPERSONATION_COOKIE_NAME } from '../../pubsub/constants';
import { broadcastImpersonateEnd } from '../../auth/hooks/AuthProxy';

const broadcastLogin = () => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  if (typeof window !== 'undefined') {
    dynamicImport(() => import(/* webpackChunkName: "AuthProxy" */ '../../auth/hooks/AuthProxy'))
      .then(mod => mod?.broadcastLogin?.())
      .catch(captureException);
  }
};

const broadcastLogout = () => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  if (typeof window !== 'undefined') {
    dynamicImport(() => import(/* webpackChunkName: "AuthProxy" */ '../../auth/hooks/AuthProxy'))
      .then(mod => mod?.broadcastLogout?.())
      .catch(captureException);
  }
};

/**
 * Thought: It may make sense to push all of the submissionError
 * logic and things like that down to the level of the onSubmits,
 * and just make these... hmm. maybe...
 */

// app/reducer/action
export const UPDATE_USER = 'common/user/UPDATE_USER';
export const CLEAR_USER = 'common/user/CLEAR_USER';
export const SET_LOGGED_IN = 'common/user/SET_LOGGED_IN';
export const SET_BOATS = 'common/user/SET_BOATS';
export const SET_USER_EMAIL = 'common/user/SET_USER_EMAIL';
export const SET_MFA_METHOD = 'common/user/SET_MFA_METHOD';
export const SET_MFA_METHODS = 'common/user/SET_MFA_METHODS';
export const SET_MASKED_NUMBER = 'common/user/SET_MASKED_NUMBER';

const INITIAL_USER_STATE = fromJS({
  currency: {
    code: 'USD',
    symbol: '$',
  },
  hasPhoto: false,
  // Null is important and intentional here. Until the user get call
  // has completed, we don't know whether the user login call has
  // succeeded or failed, and in situations such as LoginRequiredRoute,
  // we want to be able to explicitly check for a False to know to
  // redirect the user to the login page.
  loggedIn: null,
  MFAMethod: null,
  MFAMethods: null,
  maskedNumber: null,
});

/**
 * NOTE:
 * for the way that UPDATE_USER is used, it's possible for the user state
 * to become desynchronised. Imagine the user logs in, receives their boats,
 * then logs in as another user without also getting boats - then the new
 * user is showing the old boats.
 *
 * I don't think that this is a problem now, but managing it may become
 * an issue in the future.
 */

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function userReducer(user = INITIAL_USER_STATE, action: AnyAction) {
  switch (action.type) {
    case SET_BOATS:
      return user.set('boats', fromJS(action.boats));
    case SET_USER_EMAIL:
      return user.set('email', action.email);
    case UPDATE_USER:
      return user.merge(action.user);
    case SET_LOGGED_IN:
      return user.set('loggedIn', true);
    case SET_MFA_METHOD:
      return user.set('userMFAMethod', action.method);
    case SET_MFA_METHODS:
      return user.set('userMFAMethods', action.methods);
    case SET_MASKED_NUMBER:
      return user.set('maskedNumber', action.maskedNumber);
    case CLEAR_USER:
      // We only want to keep currency, and we overwrite loggedIn
      return Map({
        currency: user.get('currency'),
        loggedIn: false,
      });
    default:
      return user;
  }
}

export const setUserEmail = (email: string) => ({ type: SET_USER_EMAIL, email });
export const setBoats = (boats: BoatDetail[] | MinimalBoatDetail[]) => ({ type: SET_BOATS, boats });
export const updateUser = (user: ImmutableUser) => ({ type: UPDATE_USER, user });
export const setLoggedIn = () => ({ type: SET_LOGGED_IN });
export const clearUser = () => ({ type: CLEAR_USER });
export const setMFAMethod = (method: MFAMethod) => ({ type: SET_MFA_METHOD, method });
export const setMFAMethods = (methods: MFAMethod[]) => ({ type: SET_MFA_METHODS, methods });
export const setMaskedNumber = (maskedNumber: string) => (
  {
    type: SET_MASKED_NUMBER,
    maskedNumber,
  }
);

const conditionalSiftUserSet = (user: ImmutableUser) => {
  // If the hash isn't set, it means the user is
  // being impersonated, and we don't want them to
  // be tracked in that case.
  const hash = user && user.get('sift_session_id');
  if (hash) {
    setSiftUser(user.get('id'), hash);
  }
};

const postLogin: (
  user: ImmutableUser,
  dispatch: ThunkDispatch<ReduxState, {}, Action>,
  getState: () => ReduxState
) => void = (user, dispatch, getState) => {
  configureScope(scope => scope.setUser({
    id: user.get('pk'),
    email: user.get('email'),
  }));
  conditionalSiftUserSet(user);
  dispatch(updateUser(user));
  dispatch(setLoggedIn());
  broadcastLogin();
  if (!getState().requestCurrency) {
    dispatch(setRequestCurrency(user.get('currency')));
  }
};

type AuthUserGetThunk = ThunkAction<Promise<ImmutableUser>, ReduxState, {}, Action>;
const authUserGet = (): AuthUserGetThunk => (
  async (dispatch, getState) => {
    const response = await dispatch(apiFetchThunk('/auth/user/')).catch(ignoreSpecificNetworkErrors);
    if (!response || response.status === 403) {
      // A 403 indicates the user isn't logged in, so we'll
      // clear any existing user data just to be sure.
      dispatch(clearUser());
      return Map();
    }
    if (!response.ok) {
      fetchCatch(response);
      return Map();
    }
    const user: ImmutableUser = await response.json().then(fromJS);
    const currentUserId = getState()?.user?.get('id');
    const newUserId = user?.get('id');
    if (typeof window !== 'undefined' && currentUserId && currentUserId !== newUserId) {
      window.location.reload();
    }
    dispatch(updateUser(user));
    dispatch(setLoggedIn());
    return user;
  }
);

type UpdateUserFromAuthThunk = ThunkAction<Promise<ImmutableUser>, ReduxState, {}, Action>;
export const updateUserFromAuth = (): UpdateUserFromAuthThunk => (
  async (dispatch, getState) => {
    const user = await dispatch(authUserGet());
    if (user.isEmpty()) {
      return user;
    }
    conditionalSiftUserSet(user);
    if (!getState().requestCurrency) {
      dispatch(setRequestCurrency(user.get('currency')));
    }
    return user;
  });

type LogoutCallThunk = ThunkAction<Promise<void>, ReduxState, {}, Action>;
export const logoutCall = (): LogoutCallThunk => (
  dispatch => (
    dispatch(apiFetchThunk(
      '/auth/logout/',
      { method: 'POST' },
    )).then((response: Response) => {
      if (response.status !== 200) {
        fetchCatch(response);
      } else {
        clearSiftUser();
        // TODO: this needs to be updated.
        configureScope(scope => scope.setUser({}));
        dispatch(clearUser());
        broadcastLogout();
      }
    })
  ));

type UserPatchCallThunk = ThunkAction<Promise<void>, ReduxState, {}, Action>;
export const userPatchCall = (formData: Record<string, any>): UserPatchCallThunk => (
  async (dispatch, getState) => (
    dispatch(apiFetchThunk(
      '/auth/user/',
      {
        method: 'PATCH',
        body: JSON.stringify(formData),
      },
    )).then((response: Response) => {
      if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    }).then((body: User) => {
      const user: ImmutableUser = fromJS(body);
      // Notice that we don't have to unpack user, because
      // this is a user modification endpoint and not an
      // auth endpoint, which means that we're not returning
      // the auth key, so the user is the root of the response.
      dispatch(updateUser(user));

      if (!getState().requestCurrency) {
        dispatch(setRequestCurrency(user.get('currency')));
      }
      return user;
    })
  )
);

type RegisterCallThunk = ThunkAction<
Promise<ImmutableUser | GuestUser | null>,
ReduxState, {}, Action>;
export const registerCall = (formData: Record<string, any>): RegisterCallThunk => (
  async dispatch => {
    const response = await dispatch(apiFetchThunk(
      '/auth/registration/',
      {
        method: 'POST',
        body: JSON.stringify(formData),
      },
    ));
    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
    const body = await response.json();
    if (body.user) {
      const user: ImmutableUser = fromJS(body.user);
      conditionalSiftUserSet(user);
      dispatch(updateUser(user));
      dispatch(setLoggedIn());
      broadcastLogin();

      trackRegistration();
      return user;
    } if (body.message === 'Guest registration confirmation email sent.') {
      return { message: body.message, isGuest: true };
    }
    return null;
  });

type GetMFAMethodsActionThunk = ThunkAction<Promise<void>, ReduxState, {}, Action>;
type GetMFAMethodsActionArgs = {
  page?: number;
};
export const getMFAMethodsAction = (params: GetMFAMethodsActionArgs = { page: 1 }):
GetMFAMethodsActionThunk => (
  async dispatch => {
    const response = await dispatch(apiFetchThunk(
      `/auth/login/2fa/?${stringify(params)}`,
      {
        method: 'GET',
      },
    ));
    if (!response.ok) {
      throw response;
    }
    const body = await response.json();
    const { methods } = body;
    dispatch(setMFAMethods(methods));
  }
);

type LoginActionThunk = ThunkAction<Promise<unknown>, ReduxState, {}, Action>;
export const loginAction = (formData: Record<string, string>): LoginActionThunk => (
  async (dispatch, getState) => {
    // We won't do anything if the response isn't 200
    // This is to take advantage of a 403 response
    // when the user isn't logged in
    const response = await dispatch(apiFetchThunk(
      '/auth/login/',
      {
        method: 'POST',
        headers: {
          // Used to deny login requests that aren't valid
          // TODO: does this need to be unset after send? Should this be a pop?
          [RECAPTCHA_COOKIE_NAME]: Cookies.get(RECAPTCHA_COOKIE_NAME),
        },
        body: JSON.stringify(formData),
      },
    ));
    if (!response.ok) {
      // If something went wrong, throw the response directly so that
      // rffSubmitResponse can figure out what to do with it.
      const responseClone = response.clone();
      const errors = await responseClone.json();
      if (errors['2fa_required']) {
        dispatch(setMFAMethod(errors['2fa_required']));
        if (errors.masked_number) dispatch(setMaskedNumber(errors.masked_number));
        dispatch(getMFAMethodsAction());
      }
      throw response;
    }
    const body = await response.json();
    const user: ImmutableUser = fromJS(body.user);
    postLogin(user, dispatch, getState);

    return fromJS(body);
  }
);

type MFALoginActionThunk = ThunkAction<Promise<unknown>, ReduxState, {}, Action>;
type MFALoginActionArgs = {
  otp: string;
  method: MFAMethod;
  trust_device?: 1;
};
export const MFALoginAction = (
  formData: MFALoginActionArgs,
): MFALoginActionThunk => (
  async (dispatch, getState) => {
    const response = await dispatch(apiFetchThunk(
      '/auth/login/2fa/',
      {
        method: 'POST',
        body: JSON.stringify(formData),
      },
    ));
    if (!response.ok) {
      throw response;
    }
    const body = await response.json();
    const user: ImmutableUser = fromJS(body.user);
    postLogin(user, dispatch, getState);
    return fromJS(body);
  }
);

type ResendMFACodeActionThunk = ThunkAction<Promise<unknown>, ReduxState, {}, Action>;
type ResendMFACodeActionArgs = { method: MFAMethod };
export const resendMFACodeAction = (
  formData: ResendMFACodeActionArgs,
): ResendMFACodeActionThunk => (
  async dispatch => {
    const response = await dispatch(apiFetchThunk(
      '/auth/login/2fa/',
      {
        method: 'PATCH',
        body: JSON.stringify(formData),
      },
    ));
    if (formData.method === 'sms' && response.ok) {
      const { masked_number: maskedNumber } = await response.json();
      dispatch(setMaskedNumber(maskedNumber));
    }
    if (!response.ok) {
      throw response;
    }
  }
);

type VerifyMFARecoveryCodeActionThunk = ThunkAction<Promise<unknown>, ReduxState, {}, Action>;
type VerifyMFARecoveryCodeActionArgs = { code: string };
export const verifyMFARecoveryCodeAction = (
  formData: VerifyMFARecoveryCodeActionArgs,
): VerifyMFARecoveryCodeActionThunk => (
  async (dispatch, getState) => {
    const response = await dispatch(apiFetchThunk(
      '/auth/login/2fa/recovery/',
      {
        method: 'POST',
        body: JSON.stringify(formData),
      },
    ));
    if (!response.ok) {
      throw response;
    }
    const body = await response.json();
    const user: ImmutableUser = fromJS(body.user);
    postLogin(user, dispatch, getState);

    return fromJS(body);
  }
);

type GetBoatsThunk = ThunkAction<unknown, ReduxState, {}, Action>;
type GetBoatsArgs = {
  page_size?: number;
  page?: number;
  minimal?: boolean;
  show_removed?: boolean;
};
export const getBoatsCall = (parameters: GetBoatsArgs = {}): GetBoatsThunk => {
  const querystring = stringify(parameters);
  const url = querystring ? `/boats/?${querystring}` : '/boats/';
  return dispatch => dispatch(apiFetchThunk(url))
    .then((response: Response) => {
      if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    })
    .then(boats => dispatch(setBoats(boats.results || boats)))
    .catch(fetchCatch);
};

const checkIfImpersonationSession = () => !!Cookies.get(IMPERSONATION_COOKIE_NAME);

const endImpersonationSession = async () => {
  const response = await fetch('/utils/impersonation/end/');
  broadcastImpersonateEnd(response.url);
};

type LogoutThunkThunk = ThunkAction<Promise<boolean>, ReduxState, {}, Action>;
export const logoutThunk = (): LogoutThunkThunk => (
  async dispatch => {
    if (checkIfImpersonationSession()) {
      endImpersonationSession();
      return false;
    }
    return dispatch(logoutCall()).then(() => {
      dispatch(clearTrips());
      dispatch(clearTransaction());
      dispatch(clearMessages());
      return true;
    });
  }
);

/**
 * Returns a boolean representing whether the user is logged in or not.
 * Returns null when the user state is still being determined.
 */
export const getIsLoggedIn = ({ user }: ReduxState): boolean | null => user.get('loggedIn') ?? null;
